export const GET_BOOKINGS = gql`
  query GetBookings(
    $filters: BookingFiltersInput
    $pageInfo: PageInfoInput
    $isInfiniteScroll: Boolean
  ) {
    getBookings(
      filters: $filters
      pageInfo: $pageInfo
      isInfiniteScroll: $isInfiniteScroll
    ) {
      totalCount
      items {
        bookingId
        createdAt
        updatedAt
        bookingDurationInMinutes
        bookingStartTime
        bookingEndTime
        title
        description
        status
        activityLogId
        cancelledDate
        company {
          companyId
          companyName
        }
        applicant {
          applicantId
          firstName
          lastName
          phone
        }
        employee {
          employeeId
          firstName
          lastName
          phone
        }
      }
    }
  }
`

export const GET_BOOKINGS_COUNT = gql`
  query GetBookingsCount($filters: BookingFiltersInput!) {
    getBookingsCount(filters: $filters) {
      date
      count
    }
  }
`

export const CANCEL_BOOKING = gql`
  mutation CancelBooking($bookingId: String!) {
    cancelBooking(bookingId: $bookingId)
  }
`
